.modal.full .modal-dialog {
	position: fixed;
		margin: auto;
		width: 100%;
		max-width: 100vw;
		height: 100%;
		-webkit-transform: translate3d(0%, 0, 0);
		    -ms-transform: translate3d(0%, 0, 0);
		     -o-transform: translate3d(0%, 0, 0);
		        transform: translate3d(0%, 0, 0);
	}

	.modal.full .modal-content,
	.modal.full .modal-content {
		height: 100%;
		overflow-y: auto;
	}
	
	.modal.full .modal-body,
	.modal.full .modal-body {
		padding: 15px 15px 80px;
	}

/*full*/
	.modal.full .fade .modal-dialog{
		left: -320px;
		-webkit-transition: opacity 0.3s linear, full 0.3s ease-out;
		   -moz-transition: opacity 0.3s linear, full 0.3s ease-out;
		     -o-transition: opacity 0.3s linear, full 0.3s ease-out;
		        transition: opacity 0.3s linear, full 0.3s ease-out;
	}
	
	.modal.full.fade.in .modal-dialog{
		left: 0;
	}
  

/* ----- MODAL STYLE ----- */
 
.modal-content {
	border-radius: 0;
	border: none;
}

.modal.full .modal-header {
	border-bottom-color: white;
	background-color: white;
	border-bottom: 1px solid rgb(230, 233, 239);
	padding: 16px 16px 0px;
}

.gallery-item-text {
	display: flex;
    flex-flow: column;
    justify-content: center;
    font-size: 20px;
    width: 100%;
    height: 100%;
}
	 
 