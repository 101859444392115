#backroundLogin {
    background-image: url('../../img/regist.svg');
}

.pass-wrapper {
    position: relative;
    display: flex;
    margin-bottom: 14px;
  }

.eye-icon {
    position: absolute;
    top: 8px;
    right: 7px;
  }
.eye-icon:hover { 
    cursor: pointer;
  } 

.img-login {
    position: absolute;
    left: -170px;
    top: 100px;
}

.txt-login { 
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 50px;
    color: #4FBCB6;
}

.txt-login-child {
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #616569;
}

.input-text {
    height: 40px;
}

.color-grey-darker {
    color: #616569 !important;
}

.btn-login {
    background: #4FBCB6;
    border-radius: 4px;
    width: 137px;
    height: 40px;
    padding: 8px 24px;
}

.btn-forgot {
    width: 170px
}
 
.bg-login {
    /* background-color: #F9F9FA !important; */
    background: rgba(79, 188, 182, 0.2);
    border-radius: 15px;
}

.h-90 {
    height: 90% !important;
}

.h-95 {
    height: 95% !important;
}

.custom-control-input-green:focus~.custom-control-label::before {
    border-color: #28a745 !important;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25) !important;
  }
  
  .custom-control-input-green:checked~.custom-control-label::before {
    border-color: #28a745 !important;
    background-color: #28a745 !important;
  }
  
  .custom-control-input-green:focus:not(:checked)~.custom-control-label::before {
    border-color: #5bd778 !important;
  }
  
  .custom-control-input-green:not(:disabled):active~.custom-control-label::before {
    background-color: #d6f5dd !important;
    border-color: #d6f5dd !important;
  }