@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

body {
  background-color: blue;
  margin: 0 !important;
  font-family: 'IBM Plex Sans' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh !important;
  width: 100vw !important;
  background-color: white !important;
  font-size: 12px !important;
  overflow: hidden;
}

code {
  font-family: 'IBM Plex Sans' !important;
}

#root {
  overflow: auto;
  margin: 0 !important;
  padding: 0 !important;
  height: 100%;
  width: 100%;
}
