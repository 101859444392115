$theme-colors: (
    // "dark": #212121,
    "dark": #000, 
    "light": #DFF0FF,
    "primary": #4FBCB6,
    "pink": #FF516F,
    "danger": #F03738,
    "success": #3CC13B
); 

.switch {
    position: relative;
    overflow: hidden;
    max-height: 5px !important;
    margin-top: 10px !important;
    width : 70px !important;
    height: 10px;
    border-radius: 50px !important;
}

.switch-handle {
    position: relative;
    margin: 0 10px 0 10px !important;
    padding-top: 0px;
    padding-bottom: 0px;
    height: 100%;
    width: 60px !important;
    border-radius: 50px !important;
    border-width: 0 1px;
    background-color: #fff;
}
@import "~bootstrap/scss/bootstrap"