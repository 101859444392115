
.menu {
    margin-left: 66px !important;  
    overflow-x: hidden;
    display: flex;
    flex-direction: column; 
    box-shadow: inset -1px 0px 0px #e0e4e8;
    z-index: 1000;
}

.menu-teams {
    margin-left: 66px !important;
    width: 255px !important;
    overflow-x: hidden;
    display: flex;
    flex-direction: column; 
    box-shadow: inset -1px 0px 0px #e0e4e8;
    z-index: 1000;
}

span.workspace-item-name{
    font-weight: 600;
    font-size: 16px;
    white-space: nowrap;
    color: black;
}

span.workspace-item-name-child{
    font-weight: 400;
    font-size: 14px;
    white-space: nowrap;
    color: black;
}

a.nav-menu.pl-4.nav-link:hover  #more-board-option{
    opacity: 100;
}

a.nav-menu.pl-4.nav-link:hover{
    background-color: #F5F7FF;
    margin-right: 2px;
}

.menu-active {
    background-color: #F5F7FF;
    margin-right: 2px;
    color: #4FBCB6;
}

.menu-active > span{ 
    color: #4FBCB6 !important;
}

a.nav-menu.pl-4.nav-link {
    height: 40px;
}


a.nav-menu.p-2.nav-link:hover{
    background-color: #F5F7FF;
}

.color-grey {
    color: #616569;
}
 
svg.MuiSvgIcon-root.float-right.mt-1.more-board-option.MuiSvgIcon-fontSizeSmall{
    opacity: 0;
}

svg.MuiSvgIcon-root.float-right.mt-1.more-board-option.MuiSvgIcon-fontSizeSmall:hover {
    opacity: 100;
} 