.nav-scroll {
  z-index: 5;
  background: white;
  /* border-bottom: 0.1px solid rgb(211, 211, 211);
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4); */
  box-shadow: 0px 2px 4px rgba(235, 239, 255, 0.5);
}

nav a {
  color: #333 !important;
  font-weight: bold;
  font-size: 15px;
}

nav li {
  margin-left: 15px;
}

#btnup{
  /* position: relative;
  left: 230px; */
  width: 400px;
}

#col-menu-btn{
  position: relative;
  left: 25%;
}

#sup{
  position: relative;
  left: 200px;
}
#li-display1{
  position: relative;
  left: 200px;
}
#con{
  position: relative;
  left: 200px;
}
#pri{
  position: relative;
  left: 200px;
}
#why{
  position: relative;
  left: 200px;
}

nav.navbar {
  height: 10%;
}

.navbar-nav {
  /* float: left; */
}

.login-btn {
  background: #4FBCB6;
  border-radius: 5px; 
  padding: 13px;;
  width: 150px;
}

.login-btn > span {
  color: white;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  padding-left: 25px;
  padding-right: 25px;
}

.signup-btn { 
  background: rgba(79, 188, 182, 0.2);  
  border-radius: 5px;
  padding: 13px;;
  width: 470px;
}

.signup-btn > span {
  color: #4FBCB6;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  padding-left: 20px;
  padding-right: 20px; 
}

.nav-text {
  font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 125%;
    color: #616569 !important;
    flex: none;
    order: 0;
    flex-grow: 0;
    text-align: center;
    vertical-align: middle; 
    text-decoration: none !important;
    margin-right: 20px !important;
}

.login-btn a {
  font-weight: 300 !important;
  margin-bottom: 10px;
  font-size: 16px !important;
  border-color: transparent;
  cursor: pointer;
}

.login-btn:hover,
.login-btn a:hover {
  border-color: #4FBCB6 !important;
  color: #4FBCB6 !important;
}

.product:hover {
  color: #4FBCB6 !important;
}

.use-cases:hover {
  color: #4FBCB6 !important;
}

.why:hover {
  color: #4FBCB6 !important;
}

.creators:hover {
  color: rgb(73, 91, 255) !important;
}

/* HERO */
.hero-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 150px !important;
}

.hero-container-already-paid {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 55px !important;
}

.hero-container h1 {
  font-weight: bold;
  font-size: 48px;
  margin-bottom: 20px;
}

.hero-container h1 span {
  color: blueviolet;
}

.hero-container p {
  font-size: 18px;
  margin-bottom: 50px;
}

.form-input {
  font-size: 16px;
  padding: 16px 29px;
  -webkit-appearance: none;
  background-color: #f5f5f5;
  color: #333;
  border: 1px solid;
  border-color: #ccc;
  outline: none;
  text-align: left;
  -webkit-transition: border-color 0.1s ease-in-out;
  transition: border-color 0.1s ease-in-out;
  margin: 8px 16px 8px 0;
}

.form-button {
  padding: 15px;
  font-weight: 400;
  background-color: #2E4CBC;
  color: #fff;
  border-radius: 40px;
  width: 250px;
}

.form-button:hover {
  background: #4FBCB6;
}

.car {
  display: flex;
  justify-content: center;
  margin-bottom: 64px !important;
}

.car1 {
  border: 2px solid blueviolet;
  width: 900px;
}

.car2 {
  border: 2px solid rgb(233, 0, 128);
  width: 900px;
}

.car3 {
  border: 2px solid rgb(16, 209, 16);
  width: 900px;
}

/* PRODUCT */

.product-container {
  margin-top: 80px !important;
}

.product-1 {
  display: flex;
  flex-direction: row;
  margin-left: 30px;
}

.product-2 {
  display: flex;
  flex-direction: row;
}

.product-desc {
  padding: 20px;
  float: left;
}

.product-desc p:first-child {
  color: rgb(36, 166, 252);
}

.product-desc h2 {
  font-weight: 700 !important;
  font-size: 42px;
  margin-bottom: 20px;
}

.product-desc p:last-child {
  line-height: 2 !important;
}

.product-video {
  width: 70%;
}

.product-video-2 {
  width: 50%;
}

video {
  width: 100%;
}

.product-desc-2 {
  margin-top: 20px;
  width: 50%;
  margin-left: 30px;
  padding: 20px;
}

.product-desc-2 p:first-child {
  color: rgb(16, 209, 16);
}

.product-desc-2 p:last-child {
  line-height: 2 !important;
}

.product-desc-2 h2 {
  font-weight: 700 !important;
  font-size: 42px;
  margin-bottom: 20px;
}

/* DESCRIPTION */

.work-container {
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
}

.video-1-container {
  width: 50%;
  text-align: center;
  padding: 30px;
}

.video-1-container h3 {
  margin: 20px 0px;
  font-weight: 700;
}

.video-1-container p {
  line-height: 2;
}

.video-2-container {
  width: 50%;
  text-align: center;
  padding: 30px;
}

.video-2-container h3 {
  margin: 20px 0px;
  font-weight: 700;
}

.video-2-container p {
  line-height: 2;
}

/* CREATORS */

.creators-section h1 {
  font-weight: bold;
}

.teams-span {
  color: rgb(206, 42, 206);
}

.images-container {
  display: flex;
}

.creators-images {
  margin: 10px;
}

.creators-images img:first-child {
  width: 100%;
  border: 0.1px solid rgba(0, 0, 0, 0.1);
  padding: 5px;
}

.creators-images img:last-child {
  width: 120px;
  margin-left: 70px;
  margin-top: 10px;
}

.creators-images h5 {
  margin: 20px 0;
}

.signup-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.signup-section h1 {
  margin-top: 50px;
  margin-bottom: 40px;
}

/* FOOTER */
.left-footer {
  float: left;
}

.right-footer {
  float: right;
}

.legal {
  position: relative;
  top: 50px;
  left: 250px;
  width: 50%;
}

.legal img {
  position: relative;
  left: 10px;
}

.btn:hover {
  color: white !important;
}

/* .form-button:hover {
  background: #E0E4E8 !important;
} */

.span-article {
  overflow: hidden;
  display: inline-block;
  margin: 0.00px 0.00px;
  border: 0.00px solid #000000;
  transform: rotate(0.00rad) translateZ(0px);
  -webkit-transform: rotate(0.00rad) translateZ(0px);
  width: 565.40px;
  height: 230.60px;
}

.img-1 {
  width: 609.00px;
  height: 392.00px;
  margin-left: -24.34px;
  margin-top: -107.00px;
  transform: rotate(0.00rad) translateZ(0px);
  -webkit-transform: rotate(0.00rad) translateZ(0px);
}

.img-2 {
  width: 623.73px;
  height: 256.53px;
  margin-left: 0.00px;
  margin-top: 0.00px;
  transform: rotate(0.00rad) translateZ(0px);
  -webkit-transform: rotate(0.00rad) translateZ(0px);
}

.c-5 {
  background-color: #ffffff;
  max-width: 468pt;
  padding: 72pt 72pt 72pt 72pt;
}

.display-text{
  font-family: IBM Plex Sans;
font-style: normal;
font-weight: bold;
font-size: 36px;
line-height: 150%;
/* or 54px */

/* cyan */
color: #4FBCB6;
}

.hero-text {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 175%;
  /* or 35px */
  
  /* grey-darker */
  color: #616569;
  padding-top: 20px;
}

.btn-free { 
  padding: 10px 66px;
  background: #4FBCB6;
  border-radius: 8px;
  width: 78%;
}

.btn-free > span {
  font-family: IBM Plex Sans;
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 175%;
/* identical to box height, or 35px */
text-align: center;

/* white */
color: #FFFFFF;

/* Inside Auto Layout */
flex: none;
order: 0;
flex-grow: 0;
margin: 0px 10px;
}

.display-text-black {
  font-family: IBM Plex Sans;
font-style: normal;
font-weight: bold;
font-size: 36px;
line-height: 150%;
/* or 54px */

color: #000000;
}

.hero-text-black {
  font-family: IBM Plex Sans;
font-style: normal;
font-weight: normal;
font-size: 20px;
line-height: 175%;
/* or 35px */

color: #000000;
}

#contact {
  background-color: #4FBCB6;
}

.display-text-contact{

  font-family: IBM Plex Sans;
font-style: normal;
font-weight: bold;
font-size: 36px;
line-height: 300%;
/* identical to box height, or 108px */

/* white */
color: #FFFFFF;
}

.display-text-already-paid{

  font-family: IBM Plex Sans;
font-style: normal;
font-weight: bold;
font-size: 24px;
/* line-height: 300%; */
position: relative;
    top: 10px;
/* identical to box height, or 108px */

/* white */
color: #FFFFFF;
}

.hero-p-contact {
  font-family: IBM Plex Sans;
font-style: normal;
font-weight: normal;
font-size: 20px;
line-height: 175%;
/* identical to box height, or 35px */

/* white */
color: #FFFFFF;
}

.hero-p-already-paid {
  font-family: IBM Plex Sans;
font-style: normal;
font-weight: normal;
font-size: 20px;
line-height: 175%;
/* identical to box height, or 35px */

/* white */
color: #FFFFFF;
padding-top: 30px;
}

.btn-submit {
  background: #FFFFFF;
  border-radius: 5px;
  color: #4FBCB6;
  border:none;
}

.ql-align-center {
  text-align: center;
}
  

.ql-video.ql-align-center { 
  margin: 0 auto;
  display: block;
  max-width: 100%;
}

.side-bar-support {
  background: linear-gradient(180deg, #F0F1F3 0%, rgba(255, 255, 255, 0) 77.88%);
}

.pricing {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 28px; 
  color: #616569;
}

.pricing-desc {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 31px; 
  color: #91979D;
}

.pricing-center {
  background: #4FBCB6;
  border-radius: 10px;
}

.btn-pricing{
  background: #4FBCB6;
  border-radius: 5px;
  padding: 8px;
}

.btn-pricing > span {
  color: white;
 
}

.btn-pricing-white{
  background: white;
  border-radius: 5px;
  padding: 8px;
}

.btn-pricing-white > span {
  color: #4FBCB6;
 
}

.text-promo {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 150%;
  color: #616569;
}

.text-promo-desc {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 150%;
  color: #C2C9D1;
}

.text-promo-white {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 150%;
  color: white;
}

.bg-promo {
  background: #4FBCB6;
  border-radius: 13px
}

.spacer-right {
  border: 1px solid #FFFFFF; 
}

.spacer-right-hidden {
  border: 1px solid #FFFFFF; 
}

.btn-promo {
  background: #FFFFFF;
  border-radius: 5px; 
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.btn-promo > span {
  color : #4FBCB6
}

#btn-create-display{
  width: 100px ;
}
#btn-login-display{
  width: 100px;
}



 /* When the browser is at least 600px and above */
 /* @media only screen and (min-device-width: 270px) and (max-device-width: 480px)  */
 @media (max-width: 575.98px)

  {
  #display-text-hero {
    font-size: 18px !important;
  }
  #perent-display-text-hero{
    margin-left: 10px;
  }
  #ya-display-text-hero{
    font-size: 15px !important;
  }
  #btn-display-text-hero{
    height: 50px;
    width: 300px;
  }
  #img-display-image-hero{
    margin-left: 10px;
    width: 350px;
  }
  #text2-display-text-hero{
    font-size: 15px !important;
  }

  #manage-display-text-product{
    font-size: 18px !important;
    margin-left: 25px;
  }
  #plan-display-text-product{
    font-size: 15px !important;
    width: 350px;
    margin-left: 25px;
  }
  #img-display-img-product{
    margin-left: 10px;
  }

  #cos-display-text-product{
    font-size: 18px !important;
    width: 350px;
    margin-left: 20px;
  }
  #get-dissplay-text-product{
    font-size: 15px !important;
    width: 350px;
    margin-left: 20px;
  }

  #real-display-text-product{
    font-size: 18px !important;
    width: 350px;
    margin-left: 10px;
  }
  #save-display-text-product{
    font-size: 15px !important;
    width: 350px;
    margin-left: 10px;
  }
  #hero2-display-img-product
  {
    margin-left: 20px;
    width: 350px;
  }
  #hero3-display-img-product{
    margin-left: 10px;
    width: 350px;
  }
  #heroimg-display-img-contact{
    margin-left: 0px;
    width: 300px;
  }
  .overflow {
    overflow-x: hidden;
  }
  .navbar-expand-sm {
    background-color: white;
  }
  #signup-btn{
    width: 150px;

  }
  #login-btn{
    width: 150px;
  }
  #btn-create-display{
    width: 100px ;
  }
  #img-register-display{
    width: 250px;
  }
  #btnup{
    position: relative;
    left: 0px;
  }
  
  #sup{
    position: relative;
    left: 0px;
  }
  #li-display1{
    position: relative;
    left: 0px;
  }
  #con{
    position: relative;
    left: 0px;
  }
  #pri{
    position: relative;
    left: 0px;
  }
  #why{
    position: relative;
    left: 0px;
  }
  
  .log{
    padding-left: 100px;
    margin-bottom: 100px;
    position: relative;
    background-color: #FFFFFF;
  }

  .listprod{
    background-color: white !important;  
    
  }
  #oke{
    background-color: white;
    height: 180px;
  }
  #btn-login-display{
    width: 100px;
  }
}

@media only screen and (min-device-width: 530px) and (max-device-width: 912px) {

  .navbar-expand-sm {
    background-color: white;
  }
  #manage-display-text-product{
    
    margin-left: 150px;
  }
  #plan-display-text-product{
    font-size: 30px !important;
    width: 400px;
    margin-left: 150px;
  }
  #cos-display-text-product{
    font-size: 30px !important;
    width: 350px;
    margin-left: 2px;
  }
  #get-dissplay-text-product{
    font-size: 30px !important;
    width: 500px;
    margin-left: 2px;
  }
  #real-display-text-product{
    font-size: 30px !important;
    width: 350px;
    margin-left: 150px;
  }
  #save-display-text-product{
    font-size: 30px !important;
    width: 600px;
    margin-left: 150px;
  }
  #display-text-hero {
    font-size: 50px !important;
  }
  #perent-display-text-hero{
    margin-left: 10px;
  }
  #hero3-display-img-product{
    margin-left: 150px;
    width: 350px;
  }
  #hero2-display-img-product
  {
    margin-left: 150px;
    width: 390px;
  }
  #img-display-img-product{
    margin-left: 150px;
  }
  #ya-display-text-hero{
    font-size: 20px !important;
  }
  #btn-create-display{
    width: 100px ;
  }
  #btnup{
    position: relative;
    left: 0px;
  }
  
  #sup{
    position: relative;
    left: 0px;
  }
  #li-display1{
    position: relative;
    left: 0px;
  }
  #con{
    position: relative;
    left: 0px;
  }
  #pri{
    position: relative;
    left: 0px;
  }
  #why{
    position: relative;
    left: 0px;
  }
  #signup-btn{
    width: 150px;
  }
  #btn-display-text-hero{
    width: 350px;
  }
  #display-text-hero {
    font-size: 50px !important;
    
  }
  #btn-create-display{
    width: 100px ;
  }
  #btn-login-display{
    width: 100px;
  }
}


/* .btn-whatsapp {
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  font-size: 18px;
  border: none;
  outline: none;
  background-color: red;
  color: white;
  cursor: pointer;
  padding: 15px;
  border-radius: 4px;
} */

.btn-whatsapp{
	position:fixed;
	width:60px;
	height:60px;
	bottom:60px;
	right:40px;
	background-color:#25d366;
	color:#FFF;
	border-radius:50px;
	text-align:center;
  font-size:30px;
	box-shadow: 2px 2px 3px #999;
  z-index:100;
}

.my-float{
	margin-top:16px;
}