
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* // responsive for android webview */

@media (max-width: 767.98px) {
  
  .content-board {
    padding-bottom: 0px !important;
  }

  .no-gutters {
    margin-left: -5px !important; 
  }

  .modal-spinner {
    width: 80px !important;
    pointer-events: auto !important;
    background-clip: padding-box !important;
    border: 0px solid rgba(0,0,0,.2) !important;
    border-radius: .3rem !important;
    outline: 0 !important;
    background-color: transparent !important;
    position: relative !important;
    left: 37% !important;
    bottom: 50px !important;
  }

  /* .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {
    position: relative !important;
    width: 100% !important;
    padding-right: 15px !important;
    padding-left: 0px !important;
  } */

  .margin-group-board.mb-5 {
    margin-bottom: 0px !important;
  }

  /* #section-to-print-board{
    width: 400px !important;
  } */

  .col {
    flex-basis: 0 !important;
    flex-grow: 1 !important;
    max-width: 1000px !important;
  }

  #modal-activities-mobile {
    width: 320px !important;
  }

  .react-grid-layout {
    /* left: 25px !important; */
  }

  .breadcrumb-mobile {
    /* position: relative;
    left: 25px; */
  }

  .react-grid-item {
    width: 295px !important;
  }

  .section-to-print-board-android {
    width: 360px !important;
    margin-left: -15px !important;
  }

  /* .btn-share-android {
    width: 89px;
    height: 36px;
  } */

  #content-popover-name {
    width: 355px;
    height: 570px;
    position: relative;
    right: 12px;
    top: 175px;
  }
  
}