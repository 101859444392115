.bg-dark-cs {
  background-color: #1A1A1A !important;
}

.bg-primary-cs {
  background-color: #2866F5 !important;
}
.bg-grey{
  background-color: #F9F9FA !important;
}

.cursor-pointer {
  cursor: pointer;
}

.font-8 {
    font-size: 8px !important;
  }
  
.font-10 {
  font-size: 10px !important;
}

.font-12 {
  font-size: 12px !important;
}

.font-13 {
  font-size: 13px !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-15 {
  font-size: 15px !important;
}

.font-16 {
  font-size: 16px !important;
}

.font-18 {
  font-size: 18px !important;
}

.font-20 {
  font-size: 20px !important;
}

.font-22 {
  font-size: 22px !important;
}

.font-24 {
  font-size: 24px !important;
}

.font-w-400 {
  font-weight: 400 !important;
}

.font-w-500 {
  font-weight: 500 !important;
}

.font-w-600 {
  font-weight: 600 !important;
}

.font-w-700 {
  font-weight: 700 !important;
}

.font-w-800 {
  font-weight: 800 !important;
}

.line-180 {
  line-height: 180%;
}


.header {
  height: 50px;
  z-index: 2000;
}

.sidebar {
  width: 66px !important; 
  height: 100%;
  z-index: 1000; 
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), #4FBCB6;
}

.shadow-cs {
  box-shadow: 0px 6px 6px #000000A8;
  border-radius: 10px !important;
}

.shadow-top-cs {
  box-shadow: 0px 6px 6px #000000A8;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

.border-cs-10 {
  border-radius: 10px !important;
  box-shadow: 0px 3px 3px #000000A8
}

.no-padding {
  padding: 0px !important; 
}

.no-margin {
  margin: 0px !important; 
}

.maps-heigh {
  height: 100% !important;
}

.form-check-input {
  position: absolute;
  margin-top: 0.4rem !important;
  margin-left: -1.25rem;
}

.chart-total-tt {
  height: 110px !important; 
}

.chart-service-problem {
  height: 110px !important; 
}

.wrapper-chart-tt {
  height: 50% !important;
}

.wrapper-chart-service-problem {
  height: 50% !important;
}

.gauge-container {
  height: 100% !important;
  width: 100% !important;
  margin: 0 auto !important;
}

.option-potential {
  margin-top: 0 !important; 
}

.gm-control-active {
  background-color: #1A1A1A !important; 
}

.dialog-scope {
  position: left;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #3D3D3D !important;
  border-radius: 0.25rem;
}

a {
  color: white !important;
  text-decoration: none;
  background-color: transparent;
}

.placeholder::placeholder { /* Firefox, Chrome, Opera */ 
  color: white !important;
} 

.placeholder:-ms-input-placeholder { /* Internet Explorer 10-11 */ 
  color: white !important;
} 

.placeholder::-ms-input-placeholder { /* Microsoft Edge */ 
  color: white !important;
} 

.card-problem {
  max-height: 280px;
  min-height: 280px;
}

.overflowX-hidden {
  overflow-x: hidden;
}

.spacer{
  border-bottom: 1px solid #e0e4e8 !important;
  box-shadow: inset 0px -1px 0px #e0e4e8;
}


.spacer-primary{
  border-bottom: 1px solid #4FBCB6 !important;
  box-shadow: inset 0px -1px 0px #4FBCB6;
}

@media only screen and (max-width: 992px) {
  .no-padding {
    padding: 15px !important;
  }

  .maps-heigh {
    height: 50vh !important;
  }

  .chart-total-tt {
    height: 180px !important;
  }

  .chart-service-problem {
    height: 500px !important; 
  }

  
  .wrapper-chart-tt {
    height: 65% !important;
  }

  .wrapper-chart-service-problem {
    height: 50% !important;
  }

  .option-potential {
    margin-top: 100px !important; 
  }
}