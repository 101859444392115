
.workspace-text { 
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #616569;
}
.menu-icon-down {
    right: 20px;
    position: relative;
    top: 7px;
}

div#popover-basic-option{
    border: none !important
}

div#popover-basic{
    border: none !important
}
.arrow{
    display: none !important;
}
.popover-body {
    background: #FFFFFF;
    box-shadow: 0px 4px 24px rgb(0 0 0 / 16%);
    border-radius: 8px;
}
.nav-menu {
    height: 40px !important;
}

.nav-menu-teams {
    height: 47px !important;
}
 
.nav-menu-member {
    height: 48px !important;
}


.nav-menu-member-pending {
    height: 61px !important;
}

.overflow-hidden {
    overflow: hidden;
}

.overflow-scroll {
    overflow: scroll;
}

.overflow-x-hidden{
    overflow-x: hidden;
}

.devider {
    box-shadow: inset 0px -1px 0px #e0e4e8; border-bottom: 1px solid #F5F7FF;  
}

div#modal-comment > .modal-content {
    border-radius: 0 !important;
}

div#modal-add-widget > .modal-content {
    overflow: hidden;
    border-radius: 0 !important;
}

div#modal-add-widget > .modal-content > .modal-header {
    border: none;
    border-bottom-color: transparent im !important;
    background-color: white !important; 
    padding: 16px 16px 0px;
    height: 80px !important; 
}

div#modal-price-list > .modal-content { 
    border-radius: 0 !important;
}

div#modal-text > .modal-content > .modal-body {
   padding: 0 !important;
}

div#quill-text > .ql-toolbar {
    padding: 15px !important;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
}

div#quill-text > .ql-container > .ql-editor {
    padding: 15px !important;
    min-height: 200px !important;
}


.modal-content {
    border-radius: 0.5rem !important;
}

.modal-title { 
    color: #000000 !important;
    font-size: 20px;
    font-weight: 400;
}

.modal-title-notif {
   font-size: 16px;
   font-weight: 600;
   line-height: 24px;
}

.modal-header {
    height: 68px !important; 
    border-bottom-color: #E0E4E8 !important;
    background-color: #F9F9FA !important;
    border-top-left-radius: 0.5rem !important;
}

.modal-body {
    min-height: 200px;
}

.btn-cancel {
    background: #FFFFFF;
    border: 1px solid #C2C9D1;
    box-sizing: border-box;
    border-radius: 4px;
    color: black;
    font-size: 16px;
    padding-left: 20px;
    padding-right: 20px;
    margin-right: 10px;
}

.btn-yes {
    background: #4FBCB6;
    border-radius: 4px;
    font-size: 16px;
    padding-left: 20px;
    padding-right: 20px;
}

.modal-footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    padding: 0.75rem;
    border-top: 1px solid #E0E4E8 !important;
    border-bottom-right-radius: calc(0.3rem - 1px);
    border-bottom-left-radius: calc(0.3rem - 1px);
}

.dashboard { 
    margin-left: 320px !important; 
    width: 100%;
    /* padding-right: 60px !important; */
}  
.icon {
    color: #676879 !important;
}

::-webkit-scrollbar {
    width: 20px;
    background-color: transparent !important;
    height: 20px;
  } 
  
::-webkit-scrollbar-track {
background-color: transparent;
}

::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
  }


  ::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
  }
/* 
::-webkit-scrollbar {
    width: 5px;
    display: none;
}
 
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 0 rgba(0,0,0,0.3); 
    border-radius: 5px;
}
 
::-webkit-scrollbar-thumb {
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 0 rgba(0,0,0,0.5); 
} 

::-webkit-scrollbar-track-piece{
    opacity: 0; 
    display: none;
} */

div[contenteditable="false"] { 
    padding: 5px;
}


div[contenteditable="true"] { 
    padding: 5px;
}

[contenteditable] {
    outline: 0px solid transparent;
}

[contenteditable="true"].single-line {
    white-space: nowrap;
    width:100%;
    overflow: hidden;
} 

[contenteditable="true"].single-line br {
    display:none;

}

[contenteditable="true"].single-line * {
    display:inline;
    white-space:nowrap;
}

.ReactVirtualized__Grid {
    outline: 0px solid transparent;
}

.board-title-view {
    font-size: 20px;
    font-weight: 500;
    color: black;
    white-space: nowrap;
}

.board-title-edit {
    font-size: 20px;
    font-weight: 500;
    color: black;
    white-space: nowrap;
    border: 2px dashed #E0E4E8;
}

.board-title {
    font-size: 20px;
    font-weight: 500;
    padding: 5px !important;
    color: black;
    border: 2px dashed transparent;
    white-space: nowrap;
}

/* additional android-view */
.board-title-android {
    font-size: 20px;
    font-weight: 500;
    /* padding: 5px !important; */
    color: black;
    border: 2px dashed transparent;
    white-space: nowrap;
}


.board-desc {
    font-size: 14px;
    font-weight: 400;
    color: #676878;
    white-space: nowrap;
}

.board-title:hover{ 
    border: 2px dashed #E0E4E8;
    padding: 5px;
}

.board-title:focus{ 
    border: 2px dashed #E0E4E8;
    padding: 5px;
}

.div-dashed {
    border: 2px dashed transparent;
}

.div-dashed:hover {
    border: 2px dashed #E0E4E8;
}

.div-dashed:focus{ 
    border: 2px dashed #E0E4E8; 
}

.board-title-widget{
    font-size: 18px;
    font-weight: 500;
    color: black;
    white-space: nowrap;
}

.board-title-widget:focus{ 
    border: 2px dashed #E0E4E8;
    padding: 5px;
}

.editable-border{
    font-size: 16px; 
    color: black;
    white-space: nowrap;
    border: 2px dashed transparent; 
}

.editable-border:focus{ 
    border: 2px dashed #E0E4E8; 
}

.editable-border:hover{ 
    border: 2px dashed #E0E4E8; 
}

.board-desc:hover + .edit-desc{ 
    /* border: 2px dashed #E0E4E8;
    padding: 5px; */
    opacity: 10;
}
.edit-desc{
    opacity: 0;
}
.edit-desc:hover{
    opacity: 10;
}
svg.MuiSvgIcon-root.align-text-top.align-text-top.m-2.float-right.cursor-pointer:hover{
    background-color: #F5F7FF;
}

span.activities.float-right{
    border: 1px solid #F5F7FF; 
    color: black;
    color: var(--primary-text-color);
    background-color: rgba(0, 0, 0, 0);
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    padding: 8px 16px;
    margin-right: 10px;
    border: 1px solid #C2C9D1;
    box-sizing: border-box;
    border-radius: 4px;
}

span.active.float-right{
    border: 1px solid #F5F7FF; 
    color: black;
    color: var(--primary-text-color);
    background-color: rgba(0, 0, 0, 0);
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    padding: 8px 16px;
    margin-right: 10px;
    border: 1px solid #4FBCB6;
    box-sizing: border-box;
    border-radius: 4px;
}

span.non-active.float-right{
    border: 1px solid #F5F7FF; 
    color: black;
    color: var(--primary-text-color);
    background-color: rgba(0, 0, 0, 0);
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    padding: 8px 16px;
    margin-right: 10px;
    border: 1px solid transparent;
    box-sizing: border-box;
    border-radius: 4px;
}

.more-board-option {
    opacity: 0;
}

span.activities.float-right:hover{
    background-color: #F5F7FF;
}

span.member.float-right{
    border: 1px solid #F5F7FF; 
    color: black;
    color: var(--primary-text-color);
    background-color: rgba(0, 0, 0, 0);
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    padding: 8px;
    border-radius: 4px;
    margin-right: 10px;
    padding-left: 10px;
    padding-right: 10px;
    margin-right: 10px;
    vertical-align: middle;
    border: 1px solid #C2C9D1;
    box-sizing: border-box;
    border-radius: 4px;
   
}

span.member.float-right:hover{
    background-color: #F5F7FF;
}

span.action.float-right{ 
    color: black;
    color: var(--primary-text-color); 
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    padding: 8px;
    border-radius: 4px;
    margin-right: 10px;
    padding-left: 10px;
    padding-right: 10px;
    margin-right: 10px;
    vertical-align: middle;
}

span.action.float-right:hover{
    background-color: #F5F7FF;
}

a.nav-menu.pl-2.pr-5.nav-menu-teams.nav-link:hover{
    background-color: #F5F7FF;
}


/* Worspace */
.content-workspace { 
    margin-left: 290px !important;   
    padding-bottom: 300px;
    background-color: #F9F9FA !important;
} 

.worspace-cover {
    height: 120px;
    width: 100%;
    background-color: white;
    object-fit: cover;
}

.delete-workspace {
    float: right;
    border: 1px solid #C2C9D1;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 5px;
    background-color: white;
    padding-left: 10px;
    height: 35px;
    cursor: pointer;
}

.delete-workspace-span {
    color: #616569;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    margin: 10px;
    vertical-align: middle;
}

.invite-workspace-pa {
    float: right;
    border: 1px solid #4FBCB6;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 5px;
    margin-left: 10px;
    padding-left: 10px;
    background-color: #4FBCB6;
    margin-right: 50px;
    height: 35px;
    cursor: pointer;
}

.invite-workspace-pa-span {
    color: white;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    margin: 10px;
    vertical-align: middle;
}

.invite-members-button {
    float: right;
    border: 1px solid #4FBCB6;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 5px;
    margin-left: 10px;
    padding-right: 10px;
    padding-left: 10px;
    background-color: #4FBCB6;
    margin-right: 50px;
    height: 35px;
    cursor: pointer;
}

.invite-members-span {
    color: white;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    margin: 10px;
    vertical-align: middle;
}

.member-list {
    background: #FFFFFF !important;
    border-radius: 12px !important; 
    width: 243px;
    height: 260px; 
    position: static;
    margin: 12px;
}
 
.price-list {
    background: #FFFFFF !important;
    border-radius: 6px !important;  
    position: static;
    margin: 12px;
    border: 1px solid #e0e4e8 
}

.price-list-active {
    background: #FFFFFF !important;
    border-radius: 6px !important;  
    position: static;
    margin: 12px; 
    border: 2px solid #4FBCB6
}

.price-list:focus {
    border: 1px solid red !important;
}

.member-name {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
}

.member-job-desc {
    font-size: 14px;
    line-height: 20px;  
    color: #616569;
}

.member-status{
    background: linear-gradient(0deg, #EBF9EB, #EBF9EB), #3CC13B;
    border-radius: 4px;
    color: #2D912C;
    padding: 5px;
    font-size: 12px;
    line-height: 18px;
    padding-left: 10px;
    padding-right: 10px;
}

span.cuttoff.float-right {
    border: 1px solid #F5F7FF;
    color: black;
    color: var(--primary-text-color);
    background-color: rgba(0, 0, 0, 0);
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    padding: 7px 16px;
    margin-right: 10px;
    border: 1px solid #C2C9D1;
    box-sizing: border-box;
    border-radius: 4px;
}

.edit-member {
    width: 115px;
    height: 32px;
    border: 1px solid #4FBCB6;
    box-sizing: border-box;
    border-radius: 6px;
    padding: 7px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #4FBCB6;
    cursor: pointer;
}

.delete-member {
    width: 115px;
    height: 32px;
    border: 1px solid #B4292A;
    box-sizing: border-box;
    border-radius: 6px;
    padding: 7px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #B4292A;
    cursor: pointer;
}

/* Content */
.content { 
    margin-left: 320px !important;   
    padding-bottom: 300px;
}

.content-board {  
    padding-bottom: 300px;
    background-color: white !important;
} 

.content-table {
    height: calc(100vh - 210px);
}

.column-index { 
    font-size: 14px;
    font-weight: 400;
    color: black;
    border: 2px dashed transparent; 
}

.row-index { 
    overflow: hidden;    
    white-space: nowrap;    
    font-size: 13px;    
    font-weight: 400;   
    color: black;     
    position: relative;   
    border: 2px dashed transparent; 
}

.cell-component {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; 
    font-size: 13px;
    font-weight: 400;
    border-right: 1px solid;
    border-right-color: #ffffff; 
    color: #666666; 
    position: relative;
    padding: 0 0 0 0;
    cursor: pointer;
    height: 40px;
}

.cell-border {
    /* box-shadow: inset 0px -1px 0px #E0E4E8, inset 1px 0.5px 0px #E0E4E8; */
    box-shadow: inset 0px -1px 0px #e0e4e8, inset -1px -1px 0px #e0e4e8;
}

.cell-border-top {
    box-shadow: inset 0px -1px 0px #e0e4e8, inset -1px 1px 0px #e0e4e8
}

.cell-border-bottom {
    box-shadow: inset 0px -1px 0px #e0e4e8, inset 0px 0px 0px #e0e4e8;
    
}

.cell-border-first-child {
    box-shadow: inset 0px -1px 0px #e0e4e8, inset -1px 1px 0px #e0e4e8;
    border-right: 1px solid  red !important;
    
}

.cell-border-dashed {
    border-left: 1px dashed #91979D;
    border-bottom: 1px dashed #91979D; 
    left: 5px !important; 
}

.name-cell-component {
    display: flex; 
    padding: 0 0 0 0;
    height: 100%;
    text-align: left;
}

.left-indicator {
    background-color: #4FBCB6;
    color: #4FBCB6;
}
.ml-child {
    left: 50px !important;
}
.left-indicator-child {
    background-color: #4FBCB6;
    color:#4FBCB6;
}

.left-indicator-footer {
    background-color:#E0E4E8;
    color: #E0E4E8;
    opacity: 0.5;
}

.name-cell-component .left-indicator {
    flex: 0 0 8px;
    height: 100%;
    transition: flex-basis 0.1s ease-in;
    position: relative;
}
.name-cell-component .left-indicator-child {
    flex: 0 0 8px;
    height: 100%;
    transition: flex-basis 0.1s ease-in;
    position: relative;
}
.name-cell-component .left-indicator-footer {
    flex: 0 0 8px;
    height: 100%;
    transition: flex-basis 0.1s ease-in;
    position: relative;
}

.name-cell-text {
    display: flex !important;
    flex: 1 !important;
    align-items: center;
    min-width: 0;
    padding-left: 10px;
    padding-right: 10px;
    height: 42px;
    text-decoration: none;
    color: black; 
    font-weight: 400;
    font-size: 13px;
    background-color: '#FCFCFD';
    
}

.name-cell-text-color {
    border-radius: 4px;
    margin: 8px 10px 6px 10px;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: static;
    color: white;
    height: 30px;
}

.name-cell-time-line {
    background: #91979D;
    border-radius: 24px;
    color: white;
    padding: 5px 10px 7px;
    height: 30px; 
    margin: 8px 10px 6px 10px;
}

.name-column-header {
    position: relative; 
    text-align: left;
    font-size: 16px;
    background-color: #ffffff; 
    color: #4FBCB6;
    flex: 1;
    min-width: 0px;
    display: flex;
    align-items: center;
    padding-bottom: 1px;
    z-index: 1;
}

.name-cell-text > div[contenteditable="true"] {
    padding: 2px;
    padding: 2px;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
}

svg.MuiSvgIcon-root.align-text-top.align-text-top.float-right.cursor-pointer.mr-2.ml-2.MuiSvgIcon-fontSizeSmall:hover {
    color: #0085ff !important;
}

.row-index:hover { 
    border: 2px dashed #E0E4E8; 
}

.column-index:hover{ 
    border: 2px dashed #E0E4E8; 
}

.name-cell-text-footer {
    display: flex;
    flex: 1;
    align-items: center;
    min-width: 0;
    padding-left: 10px;
    padding-right: 10px;
    height: 42px;
    text-decoration: none;
    color: black; 
    font-weight: 400;
    font-size: 13px;
    background-color: white;
}

.name-cell-text-footer-kanban {
    display: flex;
    flex: 1;
    align-items: center;
    min-width: 0;
    padding-left: 10px;
    padding-right: 10px;
    height: 42px;
    text-decoration: none;
    color: black; 
    font-weight: 400;
    font-size: 13px; 
}

.status-picker {
    flex: 0 0 32px;
    width: 112px;
    height: 32px;
    align-self: auto;
    line-height: 32px;
    width: 135px;
    text-align: center;
    margin-bottom: 8px;
    border-radius: 2px;
    color: #ffffff;
    font-weight: 400;
    cursor: pointer;
    transition: transform 0.1s ease-in-out, opacity 0.1s ease-in-out;
    position: relative;
} 

.color-rating {
    color: rgb(253, 171, 61);
}

.cell-edit {
    opacity: 0.5;
    text-align: center;
}
.cell-edit-first {   
    /* opacity: 0.5; */
    /* background-color:#4FBCB6 !important; */
    background-color: transparent
}

.cell-edit:hover{
    opacity: 10;
    background-color: transparent
}

.cell-edit-first:hover{
    opacity: 10;
    background-color: transparent
}


.div-edit-col{
    opacity: 0;
}

.div-edit-col:hover{
    opacity: 10;
    background-color: #F5F7FF !important;
}

.row-index:hover + #div-edit-col{ 
    opacity: 10;
    background-color: #F5F7FF !important; 
}

.column-index:hover +  #div-edit-col{ 
    opacity: 10; 
    background-color: #F5F7FF !important;
}

.min-wh-column {
    min-width: 42px;
    min-height: 15px;
}
 

.add-file-icon {
    opacity: 0;
}
 
.w-100.text-center.add-file:hover > .add-file-icon{     
    opacity: 10 !important; 
}

.div-permission-container {
    display: flex;
    align-items: center;
    flex-direction: row;
    cursor: pointer;
    border: 1px solid;
    border-color: #F5F7FF; 
    border-radius: 4px;
    height: 72px;
}

.div-permission-container.selected.mt-3 {
    border-color: #0085ff; 
    background-color: #d9f0ff; 
}

.div-permission {
    padding: 16px;
    font-size: 18px;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.modal-90-w {
    min-width: 80% !important;
}

.div-permission-detail-container{
    height: 100%;
    min-height: 320px;
    padding: 8px;
}

.div-permission-detail{
    display: flex;
    flex-direction: column; 
    background-color: #e5f4ff;
    border-radius: 4px;
    height: 100%;
    width: 350px;
    padding-top: 16px;
}

.permission-detail-text {
    padding-top: 8px; 
    white-space: pre-line;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.02em;
}

.details-image-wrapper {    
    height: 172px;
}

.details-image {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
}

.disabled {
    color: rgba(103, 104, 121, 0.75); 
    opacity: 0.4;
    cursor: not-allowed !important;
}
 
  
a#tab-profile-tab-info {
    margin-left: 30px; 
  } 
  
.nav-tabs .nav-link {
    border: 0px solid transparent !important; 
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {  
    border-left: none;
    border-right: none;
    border-top: none;
    border-bottom: 2px solid #0079C4!important;
    color: #0085ff; 
    width: fit-content;
}

.nav-item:hover {  
    border-left: none;
    border-right: none;
    border-top: none;
    border-bottom: 2px solid white!important; 
    width: fit-content;
}

.nav-tabs .nav-item {  
    font-weight: normal;
}

.ql-snow .ql-tooltip {
    left: 0 !important;
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0px 0px 5px #ddd;
    color: #444;
    padding: 5px 12px;
    white-space: nowrap;
}

.ql-snow a {
    color: #06c !important;
}

.ql-container {
    min-height: 50px;
}

div#quill-text > .ql-container {
   border:  none !important;
}

.post {
    margin-bottom: 15px;
    border: 1px solid;
    background-color: white; 
    border-color: #E0E4E8; 
    border-radius: 8px;
}

.post-header { 
    min-height: 76px;
}

.post-title {
    position: relative;
    z-index: 2; 
    display: flex;
}

.title {
    margin-left: 10px !important;
    display: flex;
    -ms-flex-direction: column;
    font-weight: 400;
    flex-direction: column;
    font-size: 16px;
    -ms-flex-pack: center;
    justify-content: center;
    line-height: 18px; 
    width: calc(100% - 180px);
}

.seen {   
    height: 24px;
}

 
.post-actions-like-reply{
    border-top: 1px solid;
    border-top-color: #E0E4E8; 
    height: 42px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.like-button-component{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    color: var(--primary-text-color);
    text-decoration: none;
    cursor: pointer;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.like-reply-separator{
    height: 100%;
    border-left: 1px solid;
    border-left-color: #F5F7FF; 
}

.reply-button-component {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: black;
    color: var(--primary-text-color);
    text-decoration: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.like-button-component-inner{
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% - 8px);
    height: calc(100% - 8px);
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
} 

.reply-area {
    position: relative;
    clear: both;
    border-top: 1px solid;
    border-top-color: #E0E4E8; 
}

.reply-body { 
    min-height: 35px;
    font-size: 14px;
}

.reply-body-wrapper {
    background-color: '#FCFCFD';
    color: black;
    border-radius: 24px;
    padding: 10px 20px 10px 20px;
    width: -moz-fit-content;
    width: fit-content;
    max-width: 100%;
    position: relative;
    border: solid 1px #E0E4E8;
}

.user-name{
    font-weight: 400;
    vertical-align: top;
    margin-right: 3px;
    color: #0085ff; 
    white-space: nowrap;
}

.delete-comment {
    opacity: 0;
}

.delete-comment:hover {
    opacity: 10;
}

.reply-body-wrapper:hover + #delete-comment {
    opacity: 10 !important;
}

.input-number {
    background-color: '#FCFCFD' !important;
    border:none !important; 
}

.input-number:focus {
   border:none !important;
}

.dashboard-container {
    background: rgb(245, 246, 248);
    height: 100%;
}

.border-right {
    border: 1px solid rgb(230, 233, 239);
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.border-left {
    border: 1px solid rgb(230, 233, 239);
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.bg-edit {
    background-color:white
    
}

.text-edit-active {
    color: #4FBCB6;
    padding: 4px 12px 4px 8px !important;
    border: 1px solid #4FBCB6;
    box-sizing: border-box;
    border-radius: 4px;
}

.text-edit-active-noactive {
    color:#616569;
    padding: 4px 12px 4px 8px !important;
    border: 1px solid transparent;
    box-sizing: border-box;
    border-radius: 4px;
}

.text-controll-dashboard {
    padding: 4px 12px 4px 8px !important;
}


.border-widget {
    border: 1px solid #E0E4E8;
}

.border-widget-option {
    border-left:  none !important;
    border-radius: inherit !important;
    /* border-radius: 8px !important; */
    /* border: 1px solid #E0E4E8 !important; */
    /* box-shadow: inset 0px -1px 0px #E0E4E8;  */ 
}

.galery-item {
    border: 1px solid #E0E4E8;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 4px;
    width: 52px;
    height: 52px;
    text-align: center;
}

.d-inline-block.cursor-pointer.selected {
    border: 1px solid #0085FF;
    border-radius: 5px;
}

#title-dashborad:hover + #icon-dashboard-widget{ 
    background-color: red;
}

.icon-dashboard-widget {
    /* opacity: 0; */
}

.icon-dashboard-widget:hover {
    background-color: #F5F7FF;
}
  
.only-print, .only-print * {
    visibility: hidden !important; 
}

.only-print-dashboard, .only-print-dashboard * {
    visibility: hidden !important; 
}


@media print {
    body * {
      visibility: hidden;
    }
    .no-print, .no-print * {
        visibility: hidden !important; 
    }
    #section-to-print, #section-to-print * {
      visibility: visible;
    }
    .only-print, .only-print * {
        visibility: visible !important; 
    }
    .only-print-dashboard, .only-print-dashboard * {
        visibility: visible !important; 
        -webkit-print-color-adjust:exact
    }
    
    #section-to-print {
      position: fixed;
      left: 20px;
      top: 20px;
    } 
    #section-to-print-board, #section-to-print-board * {
        visibility: visible;
      }
    #section-to-print-board {
        position: fixed;
        left: -350px;
        top: 20px;
      }
    
    /* additional */
    .section-to-print-board-android, .section-to-print-board-android * {
        visibility: visible;
      }
    .section-to-print-board-android {
        position: fixed;
        left: -350px;
        top: 20px;
    }
      
  }

  /* additional */
  .section-to-print-board-android {
    padding-left: 0xp !important;
}

.help-box {
    border-radius: 3px 3px 0 0;
    border: 2px dashed;    
    border-color: #0085ff !important;
    margin: 20px 0 10px 0;
}
  
.help-box-red {
    border-radius: 3px 3px 0 0;
    border: 2px dashed;    
    border-color: #e44258 !important;
    margin: 20px 0 10px 0;
}

*:fullscreen, *:-webkit-full-screen, *:-moz-full-screen {
    background-color: white !important;
}


.ql-container {
    height: 100%;
  }

  .ql-editor {
    height: 100%;
    min-height: 100px;
  }
  #editor-container {
    min-height: 50%;
  }

  .tooltip {
      z-index: 1050 !important;
  }

  /* Kanban */

  .kanban {  
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    /* height: 94%;  */
    position: relative;
    /* background-color: rgb(244, 245, 247); */
    background-color: white; 
    border-radius: 6px;
    transition: background-color 0.2s ease 0s;
    cursor: default;
  }

  .kanban-title {
    user-select: none;
    width: 100%;
    min-width: 300px;
    max-width: 100%;
    box-sizing: border-box;
    /* height: 35px; */
    font-size: 14px;
    font-weight: 600;
    padding: 15px; 
    position: relative;
    /* background-color: rgb(244, 245, 247); */
    background-color: white;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    transition: background-color 0.2s ease 0s;
    cursor: default;
  }

  .kanban-container {
    /* max-width: 300px;
    cursor: pointer;
    user-select: none;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 10px 15px 5px 15px; 
    border-radius: 3px;
    box-shadow: rgba(23, 43, 77, 0.2) 0px 1px 1px, rgba(23, 43, 77, 0.2) 0px 0px 1px;
    transition: background-color 140ms ease-in-out 0s, color 140ms ease-in-out 0s;
    background-color: rgb(255, 255, 255);
    color: rgb(23, 43, 77);
    filter: none; */

    background: #FFFFFF; 
    border: 1px solid #E0E4E8;
    box-sizing: border-box;
    border-radius: 8px;
    margin: 10px 15px 5px 15px; 
    overflow: hidden;
  }

  .kanban-item {
    /* min-height: 60px; */
    box-sizing: border-box;
    display: flex;
    font-size: 12px;
    font-weight: 600;
    flex-direction: column;
    padding: 12px 12px 0 12px;
  }

  .modal-spinner {
    width: 80px;  
    pointer-events: auto; 
    background-clip: padding-box;
    border: 0px solid rgba(0,0,0,.2) !important;
    border-radius: .3rem;
    outline: 0;
    background-color: transparent;
  } 

  .notif-container{
    display: flex;
    font-size: 14px;
    transition: background-color 0.1s ease-in-out;
    margin: 2px 8px 8px 0;
    border-radius: 8px;
    justify-content: space-between;

  }


  .notif-creator{
    height: 60px;
    width: 60px;
    min-height: 60px;
    min-width: 60px;
  }

  .person-bullet-image { 
    border: 1px solid;
    border-color: #ffffff;
    position: relative;
    display: flex;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    flex-shrink: 0;

  }

  .notif-content {
    display: flex;
    overflow: hidden;
    flex-direction: column;
    margin-left: 8px;
    color: black;
  }

  .notif-href {
    display: flex;
    color: transparent !important;
    text-decoration: none;
    padding: 16px;
    width: 100%;
  }

  .parent-notif:hover {
      background-color: #F5F7FF;
  }

  .popover{
    max-width: 100% !important;
    }

div#popover-teams {
    border: none !important;
    min-width: 350px;
}

div#popover-teams > .popover-body {
  padding : 0
} 

div#popover-filter {
    border: none;
    background: transparent;
}

div#popover-sort {
    border: none;
    background: transparent;
}

div#popover-settings {
    border: none;
    background: transparent;
}

div#popover-basic-week {
    width: 275px !important;
}

.text-blur {
    color : #9699a6 !important
}

.text-red {
    color : rgb(240, 55, 56) !important
}

.header-teams {
    background: #F9F9FA;
    padding: 15px;
    box-shadow: inset 0px -1px 0px #e0e4e8;
}

.new-group {
    background: #4FBCB6 !important;
    border-radius: 4px !important;
    color: white !important;
    font-size: 14px !important;
    font-weight: 500;  
    padding: 8px;
    padding-left: 10px;
    padding-right: 10px;
    margin-right: 10px;
    font-weight: 500;
    cursor: pointer;
}

.new-group > span {
    padding-left: 10px;
    padding-right: 10px;
}
 
img.addPeople {
    opacity: 0; 
} 

img.addPeople:hover {
    opacity: 1.0; 
}

img.addParagraph {
    opacity: 0; 
} 

img.addParagraph:hover {
    opacity: 1.0 !important; 
}

img.addFile {
    opacity: 0; 
} 

/* img.addFile:hover {
    opacity: 1.0 !important; 
} */
.name-cell-text:hover .w-100.text-center.add-file > img.addFile {
    opacity: 1.0 !important; 
}

.center-img {
    display: flex !important;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin:auto
}
 
.ql-toolbar.ql-snow {
    border: 1px solid #E0E4E8;
    box-sizing: border-box;
    font-family: 'IBM Plex Sans' !important;
    padding: 8px !important;
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
}

.ql-container.ql-snow {
    border-bottom-right-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
    min-height: 100px;
}

.text-comment {
    border-radius: 24px !important;
    border-color: rgb(224, 228, 232) !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    height: 40px !important;
}

.permission-container {
    background: #FFFFFF; 
    border: 1px solid #E0E4E8;
    box-sizing: border-box;
    border-radius: 8px;
}

.permission-container-active {
    background: #FFFFFF; 
    border: 1px solid #4FBCB6;
    box-sizing: border-box;
    border-radius: 8px;
}

.create-workspace {
    position: fixed; 
    bottom: 10px;
    height: 56px;
    width: 255px !important;
    box-shadow: inset 0px 1px 0px #e0e4e8;
    padding: 16px;
    align-items: center;
    text-align: center;
}

.permission-owner {
    background: #EBEFFF;
    border-radius: 4px;
    padding: 2px 10px 4px;
}

.permission-owner > span {
    color: #2E4CBC;
    font-size: 14px;
    line-height: 18px;
}

.permission-member {
    background: linear-gradient(0deg, #EBF9EB, #EBF9EB), #3CC13B;
    border-radius: 4px;;
    border-radius: 4px;
    padding: 2px 10px 4px;
}

.permission-member > span {
    color: #2D912C;
    font-size: 12px;
    line-height: 18px;
}

.permission-superadmin {
    background: #7575e7;
    border-radius: 4px;;
    border-radius: 4px;
    padding: 2px 10px 4px;
}

.permission-superadmin > span {
    color: white;
    font-size: 12px;
    line-height: 18px;
}

.permission-projectadmin {
    background: #FDEBEB;
    border-radius: 4px;;
    border-radius: 4px;
    padding: 2px 10px 4px;
}

.permission-projectadmin > span {
    color: #B4292A;
    font-size: 12px;
    line-height: 18px;
}

.permission-guest {
    background: #91979D;
    border-radius: 4px;;
    border-radius: 4px;
    padding: 2px 10px 4px;
}

.permission-guest > span {
    color: white;
    font-size: 12px;
    line-height: 18px;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    border-left: none;
    border-right: none;
    border-top: none;
    border-bottom: 2px solid #4FBCB6!important;
    color: #4FBCB6 !important;
    width: -moz-fit-content;
    width: fit-content;
}

.lable-no-wrap {
    margin: 5px 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    float: left;
    width: 180px; 
    overflow: hidden;
    padding: 1%;
}

.span-label {
    margin: 5px 0;
    text-overflow: ellipsis;
    white-space: nowrap;  
    overflow: hidden;
}

/* additional/ */
#span-filter {
    /* margin-right: 47px; */
}

.span-label-popover { 
    text-overflow: ellipsis;
    white-space: nowrap;
    float: left;
    width: 140px;
    overflow: hidden; 
    padding-left: 10px;
    padding-right: 10px;
}

.lable-no-wrap-board {
    margin: 0 0;
    padding: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    float: left;
    width: 140px; 
    overflow: hidden; 
}

.lable-no-wrap-teams {
    margin: 5px 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    float: left;
    width: 150px; 
    overflow: hidden;
    padding: 1%;
}

.nav-menu:hover{
    cursor: pointer;  
    background-color: #e0e4e8;
}

.nav-menu-member:hover{
    cursor: pointer;  
    background-color: #e0e4e8;
}

.nav-menu-member-pending:hover{
    cursor: pointer;  
    background-color: #e0e4e8;
}

.div-hover:hover{
    cursor: pointer;  
    background-color: #e0e4e8;
}

.nav-tabs > a {
   font-size: 14;
   font-weight: bold;
}
 
.profile-container {
    background: #FFFFFF;
    border-radius: 8px;
    padding: 0px;
}

.title-profile {
    background: #FFFFFF; 
    box-shadow: inset 0px -1px 0px #E0E4E8;
    width: 100%;
    padding: 12px 20px;
    height: 48px;
}

.item-profile {
    max-height: 200px;
    overflow-x: hidden;
}

.react-grid-item {
    transition: all 200ms ease;
    transition-property: left, top; 
    border: 1px solid #F0F1F3 !important;
}

.content-editable { 
    min-width: 200px;
    border: 2px solid transparent
}
.content-editable:hover {
    border: 2px dashed #E0E4E8
}

.battery-container { 
    background: #F0F1F3;
    width: 50%; 
    border-radius: 8px;
    border-color: #ededed !important; 
    text-align: center;
    transition: none;
    border-top: 5px solid;
    border-bottom: 5px solid; 
    border-color: #ededed !important; 
    border-right: 5px solid;
    min-width: 7px;  
    border-left: 5px solid;
    min-width: 7px;
}

.battery-right {
    width: 10px;
    border: 5px solid;
    border-left: none;
    border-radius: 0 3px 3px 0;
    height: 20%;
    margin: auto;
    background-color: #ededed;
    color: #ededed;
}

.circle {
    flex: 0 0 auto;
    width: 12px;
    height: 12px;
    margin: auto;
    border-radius: 50%;
    display: inline-block;
}

.battery {
    width: 10px;
    border: 5px solid;
    border-left: none;
    border-radius: 0 3px 3px 0;
    height: 30%;
    margin: auto;
    background-color: #ededed;
    color: #ededed;
}
 

.hover-battery:hover {
    opacity: 0.89
} 

.menu-controll {
    width: 28px;
    height: 28px;
    background: #FFFFFF;
    border: 1px solid #E0E4E8;
    box-sizing: border-box;
    border-radius: 24px;
    position: absolute;
    z-index: 1001;
    top: 55px;
}

table.table-fit {
    width: auto !important;
    table-layout: auto !important;
}
table.table-fit thead th, table.table-fit tfoot th {
    width: auto !important;
}

table.table-fit tbody td, table.table-fit tfoot td {
    width: auto !important;
}

.upgrade {
    background-color: #4FBCB6;
    border-radius: 4px !important;
    color: white !important;
    margin-top: 10px;
    padding: 8px;
    text-align: center;
    cursor: pointer;
}

.upgrade > span {
    color: white;
}

.bg-alice {
    background: aliceblue;
}

.bg-grey {
     background-color: #F9F9FA !important;
}

.colors-button {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    text-decoration: none;
    cursor: pointer;
    position: relative;
    display: inline-block; 
    margin-right: 7px;
    
}

.colors-button.selected {
    /* border: 1px solid #4FBCB6;  */
}

.bg-focus {
    background-color: #CFD9FE;
}

